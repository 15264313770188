import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/DSC07021.jpg";
import MapGeorgetown from "../../images/minimaps/reports/minimap-guyana-georgetown-pt.svg";
export const meta = {
  name: "guyana",
  title: "O Metal Tóxico à Sombra da Indústria do Ouro",
  intro: "Apesar do seu risco à saúde e ao ambiente, o #mercúrio é amplamente utilizado na extração de ouro. Para os garimpeiros na Amazônia, o metal tóxico é quase tão valioso como o próprio ouro.",
  by: "Por Bram Ebus (texto e fotos) e G. I. Sutherland (textos). Vídeos por Tom Laffay",
  image: Image,
  next: {
    slug: "suriname",
    title: "A Corrida do Ouro Ameaça o País mais Verde do Mundo",
    description: "Os cursos de água e os ventos do Suriname transportam mercúrio, um metal tóxico utilizado na extração de ouro, para regiões distantes das minas de ouro do país."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const Gallery = makeShortcode("Gallery");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`É tarde em Corriverton,`}</strong>{` uma pequena vila na margem oeste do Rio Corentyne, canal navegável que separa a Guiana do Suriname. Mas alguns barqueiros locais, que transportam pessoas e contrabando para o outro lado do rio, ainda estão passando o tempo em frente ao bordel da vila, bebendo cerveja e fumando maconha, enquanto batem um papo com as profissionais do sexo, com quem saíram para passar a noite. A maioria das mulheres é de refugiadas venezuelanas, que fugiram da crise em seu país de origem ou foram traficada para o exterior.`}</p>
    <p>{`Em um beco escuro próximo ao bordel, Showtime* fuma um cigarro e vigia algumas das mulheres. O guianense nativo passou anos vivendo e trabalhando nos Estados Unidos, onde afirma ter dirigido um grupo de profissionais do sexo. De volta à Guiana, ele ainda está envolvido com a indústria do sexo, mas seu principal negócio agora é movimentar o contrabando para o outro lado do rio.`}</p>
    <p>{`"Nunca será um problema levar suas coisas para lá", diz ele, apontando para o leste em direção ao rio e ao Suriname. Seu grosso colar dourado desliza no topo da sua barriga arredondada, enquanto ele se encosta casualmente contra a parede. A aplicação da lei está sempre um passo atrás. "Não importa o que façam", afirma. "`}{`[Para impedir o contrabando]`}{` Eles têm que construir uma cerca neste maldito rio."`}</p>
    <Video url="https://youtube.com/embed/fqPOWU235qI" mdxType="Video" />
    <p>{`Duas mercadorias que são frequentemente adquiridas ilegalmente são o ouro e o mercúrio e, na região do Escudo das Guianas, um não anda sem o outro. O ouro é o principal produto de exportação da Guiana, graças em grande parte ao mercúrio, o metal tóxico usado no processo de mineração. Em 2015, a Guiana produziu 19,1 toneladas de ouro, de acordo com registros oficiais, o que exigiu um valor estimado de `}<a parentName="p" {...{
        "href": "https://www.iucn.nl/-files/groene_economie/regional_mercury_report_guyana.pdf"
      }}>{`29 toneladas de mercúrio`}</a>{`. Ambos os números são provavelmente muito maiores agora, pois a produção de ouro aumentou desde 2015 e uma quantidade significativa de ouro da Guiana é traficada para o exterior.`}</p>
    <p>{`Todo esse mercúrio tem sérios impactos sobre a saúde humana e o meio ambiente, mas os esforços tomados até agora para reduzir seu uso na indústria do ouro apenas empurraram as cadeias de fornecimento para a clandestinidade, deixando muitos mineradores expostos tanto às terríveis consequências à saúde, por causa da substância tóxica, quanto aos riscos legais de participar do mercado negro.`}</p>
    <hr></hr>
    <Sidenote side="left" mdxType="Sidenote">
	<MapGeorgetown mdxType="MapGeorgetown" />
    </Sidenote>
    <p>{`A Guiana e o Suriname têm muitas coisas em comum, os mesmos ecossistemas da Amazônia, o acesso ao Oceano Atlântico Norte e o passado colonial. Ambos os países foram por séculos ocupações britânicas e holandesas, tornando-se independentes apenas no século 20.`}</p>
    <iframe src="https://datawrapper.dwcdn.net/dColo/1/"></iframe>
    <p>{`A Guiana é uma nação especialmente afetada pela pobreza. Infestada pela corrupção e pelo subdesenvolvimento, dano colateral da sua longa história colonial. A renda bruta per capita é de apenas US$ 4.760 por ano. Quase um terço da população total de 780.000 habitantes do país vive na capital, na cidade de Georgetown, onde milhares de famílias vivem em edifícios que necessitam urgentemente de reparos. Casas de madeira dilapidadas e ferro-velho urbano não são uma visão incomum.`}</p>
    <Video url="https://youtube.com/embed/GtkqArJG52Y" mdxType="Video" />
    <p>{`Ainda assim, os economistas estão optimistas quanto ao futuro do país. A Guiana está prestes a se tornar um dos países mais ricos da América Latina. Grandes depósitos de petróleo offshore  foram encontrados em 2015 e vão gerar cerca de 1,2 milhão de barris por dia muito em breve`}</p>
    <p>{`Enquanto o petróleo é o assunto na cidade de Georgetown, os 20.000 garimpeiros do país não se sentem representados por seus políticos.`}</p>
    <p>{`Em 2013, a Guiana assinou a Convenção de Minamata, um tratado global projetado para reduzir as emissões de mercúrio para proteger a saúde humana e o meio ambiente. Com o nome da cidade do Japão, onde os moradores foram envenenados por mercúrio liberado por uma fábrica local, a convenção pede a proibição do seu uso na mineração. A Guiana ratificou a convenção em 2014 e se comprometeu a reduzir o uso de mercúrio em até 75% dos níveis padrões até 2027.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Temos de conseguir essa prata para pegar ouro", diz um garimpeiro experiente no interior da Guiana.`}</p>
    </blockquote>
    <p>{`Newell Dennison, comissário da Guyana Geology and Mines Commission (GGMC), diz que os primeiros passos foram dados: "Já estamos no campo, saindo para as áreas aglomeradas", onde a GGMC discute técnicas alternativas de mineração com os próprios garimpeiros. Aproximadamente 980 mineiros já receberam demonstrações, mas sua decisão de continuar ou não com o mercúrio depende agora da sua eficácia. “Muito do que está acontecendo é determinado pelo que os amigos fazem e o que outros mineiros fazem”, acrescenta Dennison. “Reconhecemos que não se trata apenas da GGMC sair e fazer coisas... trata-se também de uma mudança cultural”.`}</p>
    <p>{`No interior da selva da Guiana, a iminente proibição do mercúrio é recebida com descontentamento pelas comunidades de mineiros artesanais e de pequena escala, que temem por seus meios de subsistência, pois sua produção de ouro depende da disponibilidade de mercúrio - também conhecido como prata-viva ou, para muitos garimpeiros, apenas como "prata".`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABynrvECkf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECERD/2gAIAQEAAQUCV2bY3QnzD//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BpX//xAAXEAADAQAAAAAAAAAAAAAAAAAAIDIx/9oACAEBAAY/AqLNT//EABsQAAICAwEAAAAAAAAAAAAAAAARASEQcYGh/9oACAEBAAE/IRpkufQxbdwJmx//2gAMAwEAAgADAAAAEAc//8QAFhEAAwAAAAAAAAAAAAAAAAAAEBFR/9oACAEDAQE/EEgf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxCdFv/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExUWFxgaH/2gAIAQEAAT8QBWvakPyPFfkbd1yqJRg9x23TyRz3n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1e663f43f784184f2980b8410879b2ed/e93cc/slider1-1.webp 300w", "/static/1e663f43f784184f2980b8410879b2ed/b0544/slider1-1.webp 600w", "/static/1e663f43f784184f2980b8410879b2ed/68fc1/slider1-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1e663f43f784184f2980b8410879b2ed/73b64/slider1-1.jpg 300w", "/static/1e663f43f784184f2980b8410879b2ed/3ad8d/slider1-1.jpg 600w", "/static/1e663f43f784184f2980b8410879b2ed/6c738/slider1-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1e663f43f784184f2980b8410879b2ed/6c738/slider1-1.jpg",
              "alt": "A iminente proibição do mercúrio é recebida com descontentamento pelas comunidades de mineiros artesanais",
              "title": "A iminente proibição do mercúrio é recebida com descontentamento pelas comunidades de mineiros artesanais",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAVcGc1GZl//EABsQAAICAwEAAAAAAAAAAAAAAAABAjEDERIh/9oACAEBAAEFAt+OiaRZDHHn/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAVEAEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAQAGPwIr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMWEhUYH/2gAIAQEAAT8h5JUexsU909FkojLom0//2gAMAwEAAgADAAAAEAPP/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8QWP/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxDMZN//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUGRsf/aAAgBAQABPxCrpAwrbGBZXJbbhcMND7OToS8BVn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abe20d9c565ffe84554a700779109812/e93cc/slider1-2.webp 300w", "/static/abe20d9c565ffe84554a700779109812/b0544/slider1-2.webp 600w", "/static/abe20d9c565ffe84554a700779109812/68fc1/slider1-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abe20d9c565ffe84554a700779109812/73b64/slider1-2.jpg 300w", "/static/abe20d9c565ffe84554a700779109812/3ad8d/slider1-2.jpg 600w", "/static/abe20d9c565ffe84554a700779109812/6c738/slider1-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/abe20d9c565ffe84554a700779109812/6c738/slider1-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAEVJs7zyR//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQISA//aAAgBAQABBQJc0KZRdTpTo3Rk/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhAhEi/9oACAEBAAY/AroWm/I6hs52FP/EABkQAQEBAQEBAAAAAAAAAAAAAAERADEhQf/aAAgBAQABPyEAvqMn3WaK65bdG40nV9w4chJNK307/9oADAMBAAIAAwAAABDr/wD/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QjIz/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QqKj/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMWFBcf/aAAgBAQABPxCVBC1NGOC7TNqM8CYVwJCP3KY1qOs9yB204J+YrbZ7Jn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4d34b06fd77ff2bf238ccced3b8a55e6/e93cc/slider1-3.webp 300w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/b0544/slider1-3.webp 600w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/68fc1/slider1-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4d34b06fd77ff2bf238ccced3b8a55e6/73b64/slider1-3.jpg 300w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/3ad8d/slider1-3.jpg 600w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/6c738/slider1-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4d34b06fd77ff2bf238ccced3b8a55e6/6c738/slider1-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB4VJham2//8QAGRABAQEAAwAAAAAAAAAAAAAAAgMBABES/9oACAEBAAEFAr51WUnV0m5rzj4Ggtu93//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ASf/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhQQISYf/aAAgBAQAGPwKNZ1CKYRH1PXs5ZK2f/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBYZH/2gAIAQEAAT8hfSgKLI3UOyk5J6vJ3r+2UFuNwW+T/9oADAMBAAIAAwAAABBbz//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8QdWFZ/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxABywv/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMUFhsZH/2gAIAQEAAT8Qg0Ku8O+9ZfOZ6N1PcNC0Kp1+YaBDOzQn377gayBBKFOGd4yAlZGf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a230acc338c77b1d6d56942ce2389e28/e93cc/slider1-4.webp 300w", "/static/a230acc338c77b1d6d56942ce2389e28/b0544/slider1-4.webp 600w", "/static/a230acc338c77b1d6d56942ce2389e28/68fc1/slider1-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a230acc338c77b1d6d56942ce2389e28/73b64/slider1-4.jpg 300w", "/static/a230acc338c77b1d6d56942ce2389e28/3ad8d/slider1-4.jpg 600w", "/static/a230acc338c77b1d6d56942ce2389e28/6c738/slider1-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a230acc338c77b1d6d56942ce2389e28/6c738/slider1-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABF2ZubHLjf//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhMDEv/aAAgBAQABBQLIXJGJmhRLfmT/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EABgQAAMBAQAAAAAAAAAAAAAAAAAQMQIh/9oACAEBAAY/AquaUIf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMUGR/9oACAEBAAE/IcRwIa81jVuaRJu/R5fA0VE//9oADAMBAAIAAwAAABAn/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAEDAQE/EAFhy//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QU2vb/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFBYZH/2gAIAQEAAT8QeCF7Ka9UBUgGskPrgz3b6xoU+S3Pg+zv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2a90523be06c260163442a74d8eef870/e93cc/slider1-6.webp 300w", "/static/2a90523be06c260163442a74d8eef870/b0544/slider1-6.webp 600w", "/static/2a90523be06c260163442a74d8eef870/68fc1/slider1-6.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2a90523be06c260163442a74d8eef870/73b64/slider1-6.jpg 300w", "/static/2a90523be06c260163442a74d8eef870/3ad8d/slider1-6.jpg 600w", "/static/2a90523be06c260163442a74d8eef870/6c738/slider1-6.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2a90523be06c260163442a74d8eef870/6c738/slider1-6.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAAByZYrKxk//8QAGhAAAgMBAQAAAAAAAAAAAAAAABEBAhIDIf/aAAgBAQABBQK9+ScGWQjJ7B//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BkY//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBESEQ/9oACAEBAAY/Aloiy53jP//EABwQAAICAgMAAAAAAAAAAAAAAAERAEEhUWGBof/aAAgBAQABPyE0iMt6jWiPLhK7qAhFooLPyA4Gn//aAAwDAQACAAMAAAAQRC//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QgE//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QAYW3/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8QYWnV8F2h7K1teMInbn1qoYChosPszQS9og2lE//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bd30245abf35761063e8e4e2b12eb9d6/e93cc/slider1-7.webp 300w", "/static/bd30245abf35761063e8e4e2b12eb9d6/b0544/slider1-7.webp 600w", "/static/bd30245abf35761063e8e4e2b12eb9d6/68fc1/slider1-7.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bd30245abf35761063e8e4e2b12eb9d6/73b64/slider1-7.jpg 300w", "/static/bd30245abf35761063e8e4e2b12eb9d6/3ad8d/slider1-7.jpg 600w", "/static/bd30245abf35761063e8e4e2b12eb9d6/6c738/slider1-7.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bd30245abf35761063e8e4e2b12eb9d6/6c738/slider1-7.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABly+kaQjGf//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQJlT51gntMqFv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAAQARICExQf/aAAgBAQAGPwLBa4Gj/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBUf/aAAgBAQABPyG7mGLekUbh4iIyHgStcJ92f//aAAwDAQACAAMAAAAQvN//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EFo6f//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QVOQGX//EABwQAQEAAgIDAAAAAAAAAAAAAAERADEhQWFxgf/aAAgBAQABPxCkO1F5AHq4aDgQdx5PZiE+4ihaJmgFdwxCaLn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2bd72220307dd97a62c3eb58954d5/e93cc/slider1-8.webp 300w", "/static/a7e2bd72220307dd97a62c3eb58954d5/b0544/slider1-8.webp 600w", "/static/a7e2bd72220307dd97a62c3eb58954d5/68fc1/slider1-8.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2bd72220307dd97a62c3eb58954d5/73b64/slider1-8.jpg 300w", "/static/a7e2bd72220307dd97a62c3eb58954d5/3ad8d/slider1-8.jpg 600w", "/static/a7e2bd72220307dd97a62c3eb58954d5/6c738/slider1-8.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a7e2bd72220307dd97a62c3eb58954d5/6c738/slider1-8.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAByZtrzooWMf/EABkQAQEAAwEAAAAAAAAAAAAAAAIAARARMf/aAAgBAQABBQIyGjjk/b//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BgX//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAgEBPwGn/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwI2O//EABwQAAICAgMAAAAAAAAAAAAAAAERACEQQTFRkf/aAAgBAQABPyFa5ugIUWWJ1CFT8gi2XcZizeP/2gAMAwEAAgADAAAAENjP/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EENJ/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAECAQE/EM1EX//EAB0QAQEAAQQDAAAAAAAAAAAAAAERACExQVFxgaH/2gAIAQEAAT8Q3CJUDK5ROG8vB37wmJ0NYpcGktS9JoYQBm6rWHH3Fuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cccefa22028a624723d3e02a2f0e0477/e93cc/slider1-9.webp 300w", "/static/cccefa22028a624723d3e02a2f0e0477/b0544/slider1-9.webp 600w", "/static/cccefa22028a624723d3e02a2f0e0477/68fc1/slider1-9.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cccefa22028a624723d3e02a2f0e0477/73b64/slider1-9.jpg 300w", "/static/cccefa22028a624723d3e02a2f0e0477/3ad8d/slider1-9.jpg 600w", "/static/cccefa22028a624723d3e02a2f0e0477/6c738/slider1-9.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cccefa22028a624723d3e02a2f0e0477/6c738/slider1-9.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABXwUkRWFUP//EABkQAAMBAQEAAAAAAAAAAAAAAAECEQMAEv/aAAgBAQABBQKtorpD4YhkhzF37//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BjX//xAAcEAABAwUAAAAAAAAAAAAAAAABABAiAhESIUH/2gAIAQEABj8CkWjSbLRWJ43/xAAbEAEBAAIDAQAAAAAAAAAAAAABABEhMUFhcf/aAAgBAQABPyEw2SXxgZ1Hbc9gxttNvtm//9oADAMBAAIAAwAAABCHP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAwn//EABcRAQADAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8Qni7/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMVFBYXH/2gAIAQEAAT8QQiFUml+59Bw5mcrBVbHOnMDwdSw4q16QZhh1P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/592d3f32de4ad415d251170532370a2f/e93cc/slider1-10.webp 300w", "/static/592d3f32de4ad415d251170532370a2f/b0544/slider1-10.webp 600w", "/static/592d3f32de4ad415d251170532370a2f/68fc1/slider1-10.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/592d3f32de4ad415d251170532370a2f/73b64/slider1-10.jpg 300w", "/static/592d3f32de4ad415d251170532370a2f/3ad8d/slider1-10.jpg 600w", "/static/592d3f32de4ad415d251170532370a2f/6c738/slider1-10.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/592d3f32de4ad415d251170532370a2f/6c738/slider1-10.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`Na corrida para a eleição presidencial em março deste ano, ganha pelo candidato da oposição Irfaan Ali após uma longa e desastrosa contagem de votos, a Guyana  Gold and Diamond Miners Association (GGDMA) e o National Mining Syndicate (NMS)  publicaram uma `}<a parentName="p" {...{
        "href": "https://ggdma.com/pressnews/a-manifesto-for-mining/"
      }}>{`lista com demandas`}</a>{` para políticos que buscam o apoio político das organizações. A primeira diz que, antes de acabar gradualmente com o uso do mercúrio, o governo deve fornecer aos garimpeiros tecnologias alternativas acessíveis, juntamente com a assistência financeira e técnica necessárias para utilizá-las.`}</p>
    <p>{`Sem uma assistência maior, pouco mudará quando a proibição entrar em vigor, de acordo com os garimpeiros. O mercúrio ainda estará disponível no mercado negro - por um preço ainda maior.`}</p>
    <p>{`"Se for clandestino, o mercúrio fica mais caro, muito mais caro, porque é ilegal", diz Kennard Williams*, um operador de minas. Os comerciantes de mercúrio encarregados em fornecê-lo ficarão ricos, diz ele, enquanto os garimpeiros fazem "todo o trabalho duro".`}</p>
    <p>{`Gabriel Lall, ex-presidente do Guyana Gold Board, agência estatal que administra o mercado de ouro, concorda que a proibição do mercúrio provavelmente "facilitará a proliferação de empresas criminosas".`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Nossas fronteiras são extensas e porosas, nossos oficiais - com o devido respeito aos poucos que são limpos - são conhecidos por fazer vista grossa por um preço", diz ele.`}</p>
    </blockquote>
    <p>{`Existe uma corrupção extensa na Guiana, alerta Lall, e há agentes da polícia que frequentemente fecham os olhos para o comércio ilícito em troca de propinas. "Nossas fronteiras são extensas e porosas, nossos oficiais - com o devido respeito aos poucos que são limpos - são conhecidos por fazer vista grossa por um preço", diz ele. "Se, em qualquer elo da cadeia de supervisão, você tiver fraquezas, então é melhor talvez não ter uma cadeia". A polícia não respondeu a um pedido de comentários.`}</p>
    <hr></hr>
    <p>{`A Guiana é o único país da região do Escudo das Guianas que ainda importa mercúrio de forma legal. Entre 2008 e 2013, as importações anuais ficaram em torno de aproximadamente 70.000 quilogramas. Mas depois que a Guiana assinou a Convenção de Minamata, sinalizando seu apoio a uma redução (e futura proibição) do uso do mercúrio, as importações desmoronaram, com uma média um pouco acima de 20.000 kg nos anos seguintes. A maior parte do mercúrio proveniente principalmente da Rússia, do Reino Unido, da Índia, dos Estados Unidos e da Turquia entrou na Guiana pelo porto de Georgetown.`}</p>
    <iframe title="Importação de Mercúrio pela Guiana (2008-2018)&amp;nbsp;" aria-label="Column Chart" src="//datawrapper.dwcdn.net/Zx0Mn/1/" scrolling="no" frameBorder="0" style={{
      "border": "none"
    }} width="600" height="400"></iframe>
    <p>{`Há enormes lucros obtidos pelo comércio do mercúrio. Na Guiana, o valor no varejo do mercúrio pode chegar a 10 vezes o valor de importação. Em média, o mercúrio é importado por US$ 17,40/kg. Os atacadistas o vendem a US$ 126, mas nas regiões de mineração, o azougue chega ao balcão entre US$ 159 e US$ 234.`}</p>
    <p>{`Embora a proibição ainda não esteja em vigor, falar sobre mercúrio já é um tabu para os importadores licenciados. Nenhum deles concordou em gravar entrevista, mas um ex-importador, que armazenou mercúrio suficiente para continuar vendendo no mercado nacional, sugere que estão sendo pagas propinas para obter as licenças, que são gerenciadas pelo Pesticide and Toxic Chemicals Control Board da Guiana. "Veja, você começa a entrar em uma política mais profunda", diz ele sorrindo, enquanto aperta as mãos na frente do balcão da sua loja em Georgetown. (A instituição não comentou as alegações de propina, mas diz que “não possui informações para fundamentar o suposto tráfico de mercúrio na Guiana.”)`}</p>
    <p>{`Ele responde a cada pergunta com cuidado, ponderando silenciosamente cada resposta, antes de falar. Mas, quando perguntado se todas as importações ainda são legais, ele reage rapidamente: "Jesus Cristo, você sabe que nem sequer tocou na ponta do iceberg", ri o empresário de Georgetown. “As pessoas estão contrabandeando isso nesse momento para este país".`}</p>
    <p>{`O comerciante de Georgetown, três especialistas e fontes adicionais do setor afirmam que - além das importações legais - os comerciantes chineses estão direcionando mercúrio ilícito para o porto de Georgetown.`}</p>
    <Video url="https://youtube.com/embed/lJuft01OY1E" mdxType="Video" />
    <p>{`Os importadores chineses têm muita influência no governo da Guiana, de acordo com uma fonte guianense que trabalha para o Estado. "Esses caras têm acesso", diz ele. "Esses caras se sentem confortáveis torcendo o braço para pedir favores pelos quais pagam muito, muito bem."`}</p>
    <blockquote>
      <p parentName="blockquote">{`A facilidade com que o mercúrio avança em todas as direções da Guiana, tornou o país uma porta de entrada para canalizar aos países vizinhos`}</p>
    </blockquote>
    <p>{`Uma vez dentro da Guiana, não há restrições ao comércio ou ao movimento do mercúrio por todo o país. A facilidade com que o mercúrio avança em todas as direções da Guiana, tornou o país uma porta de entrada para canalizar aos países vizinhos, de acordo com o ex-ministro do meio ambiente da Guiana, Raphael Trotman. “Tenho certeza do tráfico transfronteiriço”, escreveu via WhatsApp. “A maior parte das informações que recebi fala do Suriname, mas não posso descartar as outras.”`}</p>
    <hr></hr>
    <p>{`No centro de Georgetown, pequenas kombis pegam passageiros e os levam para todos os cantos do país. Os veículos, pintados com cores e desenhos chamativos, ziguezagueam agressivamente pelo tráfego da cidade, buzinando à menor provocação. Muitas das vans são destinadas aos postos remotos na selva.`}</p>
    <p>{`Um motorista, com destino a Lethem, uma cidade na fronteira com o Brasil, se oferece para transportar mercúrio para lá - por um valor. “Todos precisam ser pagos”, diz ele, em frente ao escritório da sua empresa. "Porque, lembre-se, é uma chance que você agarra. Se vamos contrabandear, vai sair caro.” Os motoristas cobram até US$ 500 adicionais para levar um frasco de mercúrio, que contém 34,5 kg de azougue, para o outro lado do rio em Lethem. Eles usam travessias informais, para evitar a Polícia Federal brasileira. Uma vez em segurança no Brasil, os motoristas de táxi carregam o mercúrio para o mercado local na cidade de Boa Vista. Às vezes, os carros particulares levam algumas horas a mais ao norte, para a Venezuela.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Porque, lembre-se, é uma chance que você agarra. Se vamos contrabandear, vai sair caro.”`}</p>
    </blockquote>
    <p>{`As vans também abastecem cidades mineradoras do interior da Guiana. Um fluxo constante de vans, empilhadas com pequenos equipamentos de mineração e suprimentos de alimento e lotadas de passageiros disputando o lugar na janela, fazem a viagem de oito horas até Mahdia.`}</p>
    <p>{`O enclave de mineração só foi declarado município em 2018. A maioria dos edifícios no pequeno centro da vila é feita de madeira. Existem algumas boates e uma loja de ouro em quase todas as ruas. A exuberante floresta ao redor da vila é marcada por riachos secos e enormes buracos ocres, como se um meteorito tivesse se fragmentado logo acima da cidade e deixado crateras por toda a área circundante.`}</p>
    <Video url="https://youtube.com/embed/ExEVqQRdPqk" mdxType="Video" />
    <p>{`Em um desses buracos da selva, um jovem segura firme uma mangueira de água, enquanto irrompe do bico a água sob alta pressão. Ele é chamado de "jetman" (“homem jato”). Ele lava a lama que contém ouro em poças lamacentas, onde um “pitman” ("homem da poça”) trabalha coberto de água até o peito, removendo pedras e outros pedaços grandes de lixo. A partir daí, o "marrackman" usa uma bomba de sucção para canalizar a lama das poças para dentro da caixa de comporta, mais uma engenhoca que separa pelo peso as peças grosseiras de ouro de outras partículas em suspensão.`}</p>
    <p>{`Embaixo de uma estrutura feita com gravetos de madeira e uma folha de plástico, senta-se o supervisor da operação, Kennard Williams*. Seu trabalho é capturar os minúsculos salpicos de ouro restantes na pasta lamacenta. "O ouro é como pó. É tão fino que, sem a prata, você não consegue apanhá-lo", diz ele, espalhando o líquido prateado sobre uma fina placa de amálgama.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABSHts2eUQ/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECAxES/9oACAEBAAEFAvQm2cRnlJMqXw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABEQAQAiFR/9oACAEBAAY/AogHNg1ku1//xAAaEAEBAQADAQAAAAAAAAAAAAABABEhMVFh/9oACAEBAAE/IdGYafJXltkOgyrLHqQ3/9oADAMBAAIAAwAAABBvL//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxDVf//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBRkP/EABwQAQEAAgIDAAAAAAAAAAAAAAERACExUUHh8P/aAAgBAQABPxBYq3nYwylIgfTFJ0xPWWVoG07mCU7secVrzn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32b916684070e2673394ef92d5ad98cd/e93cc/slider-2-1.webp 300w", "/static/32b916684070e2673394ef92d5ad98cd/b0544/slider-2-1.webp 600w", "/static/32b916684070e2673394ef92d5ad98cd/68fc1/slider-2-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32b916684070e2673394ef92d5ad98cd/73b64/slider-2-1.jpg 300w", "/static/32b916684070e2673394ef92d5ad98cd/3ad8d/slider-2-1.jpg 600w", "/static/32b916684070e2673394ef92d5ad98cd/6c738/slider-2-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/32b916684070e2673394ef92d5ad98cd/6c738/slider-2-1.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`A maioria dos garimpeiros de Mahdia e dos arredores não está nessa atividade pelas riquezas; eles são mineros de subsistência.`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABibE/N2ID/8QAGhABAAMAAwAAAAAAAAAAAAAAAQACEQMSI//aAAgBAQABBQLUhy4+cLQoJc62/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhkf/aAAgBAQAGPwKzD2i30c//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8hUSceinczHeD0ZgsjIR//2gAMAwEAAgADAAAAEAcP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQZFhwf/aAAgBAQABPxDSRSdr9uAJpgR47zYhePzFiWyc8Zc6NHWC04g7M//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1ffd1dfec0074983e29717a877469f81/e93cc/slider-2-2.webp 300w", "/static/1ffd1dfec0074983e29717a877469f81/b0544/slider-2-2.webp 600w", "/static/1ffd1dfec0074983e29717a877469f81/68fc1/slider-2-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1ffd1dfec0074983e29717a877469f81/73b64/slider-2-2.jpg 300w", "/static/1ffd1dfec0074983e29717a877469f81/3ad8d/slider-2-2.jpg 600w", "/static/1ffd1dfec0074983e29717a877469f81/6c738/slider-2-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1ffd1dfec0074983e29717a877469f81/6c738/slider-2-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABlrKuhRsUB//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAhIiERMh/9oACAEBAAEFAttpDEgmdIsQ8r//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAES/9oACAECAQE/AbGX/8QAGRAAAwADAAAAAAAAAAAAAAAAARARACEx/9oACAEBAAY/AhuR9wr/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAQABPyF1KCV70lfRu3S2usEo41yGrOH/2gAMAwEAAgADAAAAEHzv/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QEz//xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAgEBPxARmf/EAB0QAQADAAMAAwAAAAAAAAAAAAEAESExQWGB0fD/2gAIAQEAAT8QQ1xB8FewOZx2to9idijp1kCgE2J+4lwrkeE+4qgNVvc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/98d8579b3b4b190ef0ced63a1fd7902d/e93cc/slider-2-3.webp 300w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/b0544/slider-2-3.webp 600w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/68fc1/slider-2-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/98d8579b3b4b190ef0ced63a1fd7902d/73b64/slider-2-3.jpg 300w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/3ad8d/slider-2-3.jpg 600w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/6c738/slider-2-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/98d8579b3b4b190ef0ced63a1fd7902d/6c738/slider-2-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGTEvCoLCS//8QAGhABAAMAAwAAAAAAAAAAAAAAAQACEhETIf/aAAgBAQABBQLt9FZXPBfNqOkQn//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQAQESExQZH/2gAIAQEABj8Cxbb61hQAv//EABsQAAMBAQADAAAAAAAAAAAAAAABESFBMZGx/9oACAEBAAE/Ia9TCi7nYUAxzXn4Jx63zBTCkj//2gAMAwEAAgADAAAAEKM//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAEDAQE/EJqH/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxBw7J2//8QAGxABAAMBAQEBAAAAAAAAAAAAAQARIVExYbH/2gAIAQEAAT8QASpbZG2HhChbbX5gKrZ6Jpelpa10OzWSgrb/AFDLngQQT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bab697cd3705c541f790ac5c9ec33e2e/e93cc/slider-2-4.webp 300w", "/static/bab697cd3705c541f790ac5c9ec33e2e/b0544/slider-2-4.webp 600w", "/static/bab697cd3705c541f790ac5c9ec33e2e/68fc1/slider-2-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bab697cd3705c541f790ac5c9ec33e2e/73b64/slider-2-4.jpg 300w", "/static/bab697cd3705c541f790ac5c9ec33e2e/3ad8d/slider-2-4.jpg 600w", "/static/bab697cd3705c541f790ac5c9ec33e2e/6c738/slider-2-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bab697cd3705c541f790ac5c9ec33e2e/6c738/slider-2-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAVdR6YaRMG//xAAaEAACAgMAAAAAAAAAAAAAAAACAwABBBAR/9oACAEBAAEFAhe2W5kDIMBuc1//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIRICFB/9oACAEBAAY/AulOWik1h//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQWGB/9oACAEBAAE/Iem/TEgm3ssUnO7LKbAGbDZ//9oADAMBAAIAAwAAABB83//EABYRAQEBAAAAAAAAAAAAAAAAAAABIf/aAAgBAwEBPxCMf//EABgRAAIDAAAAAAAAAAAAAAAAAABRAREh/9oACAECAQE/ENZUs//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQWFxgf/aAAgBAQABPxAjWTFBK+xluwfKuGuYSsh7RfVxlDNrIsAWDazKzN9z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c2aee869a4b62f10a8ed1ace82c61531/e93cc/slider-2-5.webp 300w", "/static/c2aee869a4b62f10a8ed1ace82c61531/b0544/slider-2-5.webp 600w", "/static/c2aee869a4b62f10a8ed1ace82c61531/68fc1/slider-2-5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c2aee869a4b62f10a8ed1ace82c61531/73b64/slider-2-5.jpg 300w", "/static/c2aee869a4b62f10a8ed1ace82c61531/3ad8d/slider-2-5.jpg 600w", "/static/c2aee869a4b62f10a8ed1ace82c61531/6c738/slider-2-5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c2aee869a4b62f10a8ed1ace82c61531/6c738/slider-2-5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`Um gerador entra em ação e um fluxo lamacento de água corre sobre a placa. Água e lodo passam por ele, enquanto o amálgama fica preso em cima. Ocasionalmente, Williams derrama mais mercúrio sobre o prato, às vezes passando mais de 0,9 quilogramas por dia. Todo o processo leva em média alguns dias e várias placas de amálgama são usadas.`}</p>
    <Video url="https://youtube.com/embed/dOcQdbrynXo" mdxType="Video" />
    <p>{`A maioria dos garimpeiros de Mahdia e dos arredores não está nessa atividade pelas riquezas; eles são mineros de subsistência. Mesmo após longos dias de trabalho árduo, os garimpeiros mal conseguem sustentar suas famílias. Dexton Grant, um minerador experiente, que recentemente começou a trabalhar para Williams, carrega pesadas pranchas de madeira nos ombros, enquanto caminha entre as lagunas contaminadas na mina. Existem poucas oportunidades de emprego em Mahdia, além do setor de mineração, de acordo com Grant. Ele é grato pela oportunidade de colocar comida na mesa da sua família.`}</p>
    <p>{`A falta de oportunidades educacionais e de investimentos estatais na região condenou gerações de aldeães a uma vida nos poços lamacentos. Grant espera que seus filhos tenham a oportunidade de dedicar suas vidas a alguma outra coisa: "Estou nas minas para conseguir esse ouro", diz ele, e "para deixá-los ir para a escola".`}</p>
    <Video url="https://youtube.com/embed/4SJe-illSZw" mdxType="Video" />
    <p>{`Mineradores de pequena escala são geralmente pagos com base em sua produção; quanto menos ouro conseguem extrair, menor o valor pago. Se o mercúrio não puder mais ser usado, Grant diz que será sua saída do setor. "Não serve para nenhum propósito sem o mercúrio, porque ele captura muito mais ouro", diz ele. Ele está mais preocupado com o seu bem-estar econômico do que com a sua saúde.`}</p>
    <p>{`Não há um nível seguro de exposição ao mercúrio, de acordo com especialistas, mas Grant afirma que o mercúrio não afetou seu corpo. É uma crença compartilhada por muitos mineiros na Guiana.`}</p>
    <Video url="https://youtube.com/embed/noKstuzu1FQ" mdxType="Video" />
    <p>{`Harry Casey*, um morador de Mahdia que dirige um projeto de mineração nas proximidades, não entende por que as pessoas o alertam sobre o uso de mercúrio. "Isso ainda me deixa perplexo, por que o mercúrio é tão perigoso?", ele pergunta em voz alta, dirigindo seu Toyota Hilux pela selva entre os garimpos. Ele sorri ao se lembrar que brincava com mercúrio quando criança, nas visitas do seu pai à mina. Seu pai usou "baldes de mercúrio" como garimpeiro e ainda está vivo hoje. "Meu pai tem 84 anos", diz ele. "Ele fez 17 filhos com a minha mãe."`}</p>
    <p>{`Equívocos sobre os riscos à saúde da mineração com mercúrio são um problema sério na Guiana. Mas os próprios garimpeiros não são o grupo mais afetado pela contaminação por mercúrio. Na forma líquida, como os mineradores o utilizam, o mercúrio representa menos risco para a saúde humana do que na forma gasosa. Os trabalhadores das lojas de ouro, que queimam o mercúrio do amálgama, estão mais expostos aos vapores tóxicos.`}</p>
    <hr></hr>
    <p>{`No meio de um dos povoados nos campos de ouro guianenses do interior, Leroy Persaud* ri nervosamente por trás da sua grande mesa em sua loja de ouro, uma estrutura de madeira de um andar, protegida por barras de metal sólidas. Em 2013, depois de quase dois anos comprando ouro das minase dos garimpeiros, queimando o mercúrio das esponjas de amálgama e derretendo ouro em pequenos lingotes, sua saúde começou a se deteriorar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABylrTAND/xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDERASIv/aAAgBAQABBQLaxaJzHtsBKQK1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAEAJBUf/aAAgBAQAGPwIPXYconSBr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMUEhUaH/2gAIAQEAAT8haIRcArEcejSfjsyJ9rwo/9oADAMBAAIAAwAAABCT7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBkf//EAB0QAQADAAEFAAAAAAAAAAAAAAEAETEhQVFhcaH/2gAIAQEAAT8QQzO7NHavsSoyOhfUpkQVVo8RKbGvECC7F3Kquq8s/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/e93cc/DSC07015.webp 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/b0544/DSC07015.webp 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/68fc1/DSC07015.webp 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/0b978/DSC07015.webp 1400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/73b64/DSC07015.jpg 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/3ad8d/DSC07015.jpg 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/22cab/DSC07015.jpg 1400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg",
            "alt": "O ouro amalgamado",
            "title": "O ouro amalgamado",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`O ouro amalgamado`}</em></p>
    <p>{`"As coisas para mim começaram a sair do controle", explica Persaud. Mais tarde, naquele ano, começou a acordar com dores de cabeça, a ter diarreia e vômito e foi perdendo a visão. Ele também ficou temperamental. "Acabei batendo na minha namorada", diz ele. "Eu nunca costumava fazer isso." Em 2013, Persaud foi à clínica local em seu povoado, onde testou negativo para malária e dengue. Seus sintomas continuaram a piorar e, após um incidente particularmente traumático, no qual Persaud começou a tremer incontrolavelmente e quase perdeu completamente a visão, seus médicos locais o enviaram para a capital. "Pensei que fosse morrer", recorda.`}</p>
    <p>{`Nos Laboratórios Eureka, em Georgetown, a única instalação então equipada para testar o envenenamento por mercúrio, seus exames de sangue mostraram que seus níveis de mercúrio eram de 160 nanogramas por mililitro (ng/mL), ao menos 10 vezes acima dos níveis normais. Os médicos o aconselharam a deixar seu trabalho e a região de mineração, mas não existia tratamento disponível na Guiana.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"O médico no Brasil `}{`[me disse]`}{`: 'Deixe o trabalho, se quiser viver'", diz Persaud. “Mas esse é o único trabalho que sei fazer para sobreviver e `}{`[sustentar]`}{` minha família.`}</p>
    </blockquote>
    <p>{`Os médicos o aconselharam a deixar seu trabalho e a região de mineração, mas não existia tratamento disponível na Guiana.`}</p>
    <p>{`Uma ex-namorada exortou-o a ir a um hospital em Manaus, Brasil, mas, segundo Persaud, o tratamento era caro e ele não tinha dinheiro.. Para aumentar os 2,5 milhões de GYD necessários para a viagem e o tratamento, ele voltou a trabalhar em sua loja de ouro. Quando finalmente chegou ao hospital de Santa Júlia, em Manaus, os médicos não entendiam como ele ainda estava vivo: os testes mais recentes revelaram que os níveis de mercúrio no seu sangue tinham subido para 320 ng/mL. "O médico no Brasil `}{`[me disse]`}{`: 'Deixe o trabalho, se quiser viver'", diz Persaud. “Mas esse é o único trabalho que sei fazer para sobreviver e `}{`[sustentar]`}{` minha família. Mas voltei preparado.”`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHjyZLIJf/EABoQAQABBQAAAAAAAAAAAAAAAAECABASITH/2gAIAQEAAQUC7aM8QUXbX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwFkf//EABYQAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwKibf/EABsQAQEBAAIDAAAAAAAAAAAAAAERABAhMUFR/9oACAEBAAE/IWe7fgZ6o4YRytIPCZ0Xj//aAAwDAQACAAMAAAAQMC//xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8QIEz/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QdXZa/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITEQ/9oACAEBAAE/ENQ4csXAELY4MrNtvs3poiki9Ut+f//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e3e966b96fd870b528c8c81eb7dfa065/e93cc/DSC07030.webp 300w", "/static/e3e966b96fd870b528c8c81eb7dfa065/b0544/DSC07030.webp 600w", "/static/e3e966b96fd870b528c8c81eb7dfa065/68fc1/DSC07030.webp 1200w", "/static/e3e966b96fd870b528c8c81eb7dfa065/4b075/DSC07030.webp 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e3e966b96fd870b528c8c81eb7dfa065/73b64/DSC07030.jpg 300w", "/static/e3e966b96fd870b528c8c81eb7dfa065/3ad8d/DSC07030.jpg 600w", "/static/e3e966b96fd870b528c8c81eb7dfa065/6c738/DSC07030.jpg 1200w", "/static/e3e966b96fd870b528c8c81eb7dfa065/38b44/DSC07030.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e3e966b96fd870b528c8c81eb7dfa065/6c738/DSC07030.jpg",
            "alt": "Em uma loja de ouro, o mercúrio é queimado antes de derreter o ouro em pequenos lingotes.",
            "title": "Em uma loja de ouro, o mercúrio é queimado antes de derreter o ouro em pequenos lingotes.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Em uma loja de ouro, o mercúrio é queimado antes de derreter o ouro em pequenos lingotes.`}</em></p>
    <p>{`Persaud continua a queimar o amálgama tóxico que os garimpeiros lhe vendem. Mas agora faz isso totalmente equipado, com uma máscara e um exaustor. Persaud argumenta que sua atividade é "90% segura.” O tratamento trazido do Brasil, combinado com uma dieta saudável e rica em vitaminas e uma rotina de exercícios para transpirar mercúrio pelos poros, o ajudaram a recuperar seus níveis de 10ng/mL. "Você realmente não vê isso, mas sente o cheiro", Persaud ri. "O cheiro que eu costumava ter não existe mais."`}</p>
    <hr></hr>
    <p>{`Hoje, sete anos depois de Persaud fazer a jornada para a capital, os mineradores que desejam fazer o teste de mercúrio na Guiana ainda precisam ir aos Laboratórios Eureka em Thomas Street, em Georgetown. Lá dentro, a Dra. Rhonda Archer examina pacientes na cama do hospital em seu consultório, separados da sua mesa por uma cortina azul clara.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABT1kENo0mUprGtOAP/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAECAxESMTP/2gAIAQEAAQUCksLJxZyi5nTk6vm5C2T1jWjWHh//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwEj/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAEREDFBAhIhUWH/2gAIAQEABj8CnUxbreHAukOM4QpGmTkvSFT/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMVFxQWGB/9oACAEBAAE/ISNwNYhAAjT49yzOkEvVpdcwBDEiwAOyYKmsXAC5TghxD5CTZqANR6l6T//aAAwDAQACAAMAAAAQYx8C/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEhEBH/2gAIAQMBAT8QThwcz//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QC9Zcbcsv/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBUWFxof/aAAgBAQABPxAXfBGlzQEuNfH4wMBUaQ5xxxbjtGfNmTvrHgcmiSgbguCEO6hS9YkQ0hqDAhPQ2wg5s5t/MZ0RPC4pd53XP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/33838494518738afe5f24b53cd7c7d9a/e93cc/DSC07416.webp 300w", "/static/33838494518738afe5f24b53cd7c7d9a/b0544/DSC07416.webp 600w", "/static/33838494518738afe5f24b53cd7c7d9a/8d2ea/DSC07416.webp 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/33838494518738afe5f24b53cd7c7d9a/73b64/DSC07416.jpg 300w", "/static/33838494518738afe5f24b53cd7c7d9a/3ad8d/DSC07416.jpg 600w", "/static/33838494518738afe5f24b53cd7c7d9a/c60e9/DSC07416.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/33838494518738afe5f24b53cd7c7d9a/c60e9/DSC07416.jpg",
            "alt": "Mineiros que queimam o mercúrio em uma mina de ouro",
            "title": "Mineiros que queimam o mercúrio em uma mina de ouro",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Mineiros que queimam o mercúrio em uma mina de ouro.`}</em></p>
    <p>{`Archer é especialista em envenenamento por mercúrio. Ela analisa uma longa lista dos seus potenciais efeitos à saúde: doenças respiratórias, problemas neurológicos, danos ao fígado, aos rins e ao sistema imunológico, para citar alguns. Mas ela não conseguiu fazer muito por seus pacientes na Guiana, até que o tratamento se tornasse disponível em 2018. Naquele ano, quatro funcionários da Guyana Geology and Mines Commission morreram durante um período de seis meses em um laboratório administrado pelo Guiana Gold Board, onde o mercúrio era queimado do ouro bruto. Os funcionários culparam os vapores de mercúrio. O laboratório foi posteriormente fechado e, desde então, o tratamento com mercúrio se tornou disponível, importado principalmente dos EUA.`}</p>
    <p>{`Antes disso, apenas 5 a 10 pessoas por ano vinham ver Archer em Georgetown para fazer um teste de mercúrio; a maioria das pessoas não se preocupava com isso, por saber que a ajuda médica não estava disponível.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Desde 2018, o número de mineradores que procuraram atendimento médico com ela dobrou, mas são apenas 10 a 20 pessoas por ano.`}</p>
    </blockquote>
    <p>{`Enquanto isso, há até 20.000 garimpeiros de pequena escala na Guiana, e a grande maioria trabalha com mercúrio diariamente. Archer teme que poucos garimpeiros estejam procurando ajuda para tratar envenenamento por mercúrio, mesmo agora que o tratamento está disponível na capital.`}</p>
    <p>{`"O teste de mercúrio é meio caro, em comparação com o salário de uma pessoa neste país", diz Archer. "Isso parece ser um impedimento." Exames de sangue para analisar o envenenamento por mercúrio e a bateria de outros exames necessários para determinar o tamanho do dano causado em todo o corpo podem custar mais de 40.000 GYD (o equivalente a quase US$ 200), ou mais da metade do salário mensal de muitos mineradores de pequena escala. O tratamento subsequente custa, em média, 25.000 GYD adicionais.`}</p>
    <hr></hr>
    <p>{`A oração matinal estala pelos alto-falantes da mesquita local em Corriverton às 5 horas da manhã. Ainda está escuro, exceto por alguns postes de luz tremeluzentes. De repente, o som da mesquita para, quando um apagão envolve a vila. Com a primeira luz do sol sobre o rio Corentyne, um sonolento contrabandista surge na frente de uma casa e passa pela entrada dos fundos de um pátio para abrir os portões de metal que levam ao rio. É hora de ir.`}</p>
    <Video url="https://youtube.com/embed/eOLHxmi8WV4" mdxType="Video" />
    <p>{`O motor de um barco veloz ganha vida ao lado do cais próximo. A uma curta distância, um indiano entra na água até a cintura e se lava para a oração matinal; ele não presta atenção ao que acontece ao seu lado. O contrabando geralmente é movimentado à noite, transportado pelo rio para o Suriname em travessias informais, conhecidas como “a rota inversa”. Isto é, se a mudança das marés do mar próximo permitir uma passagem segura pelo rio Corentyne. Ontem à noite a maré estava muito baixa, mas o capitão não está preocupado. “`}{`[O contrabando]`}{` nunca é apreendido quando o barco está na água”, diz ele, afastando-se da margem do rio em direção ao Suriname.`}</p>
    <p>{`"Muitas pessoas ganham dinheiro com a rota inversa", acrescenta. O mercúrio, que é armazenado em frascos de metal pesado, cada um custando até US$ 8.000, é transportado pelo rio para o Suriname em vários pontos ao longo da costa. Os contrabandistas às vezes colocam seis ou sete frascos em cada barco.`}</p>
    <p>{`O tráfico de mercúrio também é um negócio estável, se não lucrativo, para os capitães, que cobram dezenas de milhares de dólares guianenses por cada viagem ao Suriname, segundo Showtime. "É ilegal por lá, mas é legal por aqui, então você verá que a demanda é absurdamente alta", diz Showtime, insistindo nas cervejas e no rum da noite anterior em Corriverton. Pequenas quantidades são transportadas de cada vez e, se comparadas com o transporte de drogas como cocaína pela mesma rota, as consequências legais são muito menores. Mas ainda assim é um risco.`}</p>
    <p>{`“Se você for pego pela polícia no Suriname, você vai para a cadeia”, diz o capitão, depois de desligar o motor barulhento no meio do rio Corentyne. Mas se tudo correr bem, acrescenta, “o cara que comprou vai estar do outro lado esperando com um táxi ou com o carro, seja lá o que for. Quando o deixamos, ele o recolhe.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`As rotas de tráfico são tão fluidas quanto o próprio mercúrio. Elas mudam rapidamente, dependendo de onde a polícia concentra sua atenção e dos mecanismos de oferta e demanda`}</p>
    </blockquote>
    <p>{`As rotas de tráfico são tão fluidas quanto o próprio mercúrio. Elas mudam rapidamente, dependendo de onde a polícia concentra sua atenção e dos mecanismos de oferta e demanda em constante mudança, que ditam o mercado negro de minerais. Até recentemente, este local exato era uma rota inversa ativa do tráfico de mercúrio. Um novo posto de controle no lado surinamês do rio desviou o fluxo de mercúrio. Agora os frascos são levados rio acima, onde as margens do rio estão cobertas por uma densa floresta.`}</p>
    <p>{`"Ele ainda está indo para o Suriname", diz ele, mas agora é levado para "onde não existem olhos".`}</p>
    <p><em parentName="p">{`*`}{`Alguns nomes foram mudados para proteger as identidades dos entrevistados.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      